import { setupWorker, rest } from 'msw';
import media from './data/feeds/media.json';
import { replaceRemoteImageSrc } from './feed-handlers.ts';
import mockFavorites from './data/feeds/favorites.ts';
import mockContinueWatching from './data/feeds/continue-watching.json';
import mockEpg from './data/feeds/epg-client.json';

const worker = setupWorker(
  rest.get(
    'https://pipes2-server-example.herokuapp.com/media',
    (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(media)));
    }
  ),
  rest.post('*/api/is-logged-in', (_, res, ctx) => {
    return res(ctx.json({ isLoggedIn: true }));
  }),
  rest.post('*/api/favorite-action', async (req, res, ctx) => {
    const requestBody = await req.text();

    const urlSearchParams = new URLSearchParams(requestBody);

    const stringifyEntry = urlSearchParams.get('stringifyEntry');
    const entry = JSON.parse(stringifyEntry!);
    const favAction = urlSearchParams.get('favAction');

    return res(
      ctx.status(201),
      ctx.json({
        favAction,
        entry,
      })
    );
  }),
  rest.get('*/api/favorites', async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFavorites));
  }),
  rest.get('*/api/client-feed', async (req, res, ctx) => {
    const url = new URL(req.url);

    const feedUrl = url.searchParams.get('feed-url');

    if (feedUrl === '/api/favorites') {
      return res(ctx.status(200), ctx.json(mockFavorites));
    }
    if (feedUrl === '/api/continue-watching') {
      return res(ctx.status(200), ctx.json(mockContinueWatching.feed));
    }
  }),
  rest.get('*/api/continue-watching', async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContinueWatching));
  }),
  rest.post('*/api/epg-programs', async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(replaceRemoteImageSrc(mockEpg)));
  })
);

export async function prepare() {
  if (window.isMock) {
    await import('../../public/mockServiceWorker.js?worker');
    return worker.start();
  }

  return Promise.resolve();
}

export default worker;
