import {
  rest,
  type DefaultRequestMultipartBody,
  type MockedRequest,
  type RestHandler,
} from 'msw';

import media from './data/feeds/media.json';
import epg from './data/feeds/epg.json';
import homeFeatured from './data/feeds/home-featured.json';
import featuredGenre from './data/feeds/featured-genre.json';
import genres from './data/feeds/genres.json';
import channels from './data/feeds/channels.json';
import channelsEpg from './data/feeds/channels-epg.json';
import logger from '~/services/logger';

const feedHandlers: Array<
  RestHandler<MockedRequest<DefaultRequestMultipartBody>>
> = [
  rest.get(
    'https://pipes2-server-example.herokuapp.com/media',
    async (req, res, ctx) => {
      const mediaId = req.url.searchParams.get('byId');
      const q = req.url.searchParams.get('q');
      const delay = req.url.searchParams.get('delay');

      if (delay)
        await new Promise((resolve) => setTimeout(resolve, Number(delay)));

      if (mediaId) {
        return res(
          ctx.json(
            replaceRemoteImageSrc({
              id: 'https://pipes2-server-example.herokuapp.com/media',
              type: {
                value: 'feed',
              },
              next: 'https://pipes2-server-example.herokuapp.com/media?page=2',
              entry: [
                {
                  id: `show-${mediaId}`,
                  title: `Show ${mediaId} Title`,
                  summary: `This is the summary of show ${mediaId}. Here will be a short description of the show. You can configure the number of shown lines in the cell style.`,
                  type: { value: 'video' },
                  content: {
                    src: 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8', // TODO: change to local video?
                    type: 'video/hls',
                  },
                  extensions: {
                    ratings: '10',
                    StarRatingIcons: '*',
                    year: '2024',
                  },
                  media_group: [
                    {
                      type: 'image',
                      media_item: [
                        {
                          src: 'https://pipes2-server-example.herokuapp.com/images/full-16x9.png?show-1',
                          key: 'full-16x9',
                        },
                        {
                          src: 'https://pipes2-server-example.herokuapp.com/images/half-2x3.png?show-1',
                          key: 'half-2x3',
                        },
                        {
                          src: 'https://pipes2-server-example.herokuapp.com/images/third-1x1.png?show-1',
                          key: 'third-1x1',
                        },
                        {
                          src: 'https://pipes2-server-example.herokuapp.com/images/channel-1.png',
                          key: 'v1',
                        },
                        {
                          src: 'https://pipes2-server-example.herokuapp.com/images/channel-1-V2.png',
                          key: 'v2',
                        },
                      ],
                    },
                  ],
                },
              ],
            })
          )
        );
      }

      if (q) {
        const searchResult = media.entry.filter((e: { title: string }) =>
          e.title.includes(q)
        );

        return res(
          ctx.json(replaceRemoteImageSrc({ ...media, entry: searchResult }))
        );
      }

      return res(ctx.json(replaceRemoteImageSrc(media)));
    }
  ),
  rest.get(
    'https://pipes2-server-example.herokuapp.com/epg',
    async (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(epg)));
    }
  ),
  rest.get(
    'https://pipes2-server-example.herokuapp.com/collections/homeFeatured',
    async (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(homeFeatured)));
    }
  ),
  rest.get(
    'https://pipes2-server-example.herokuapp.com/collections/featuredGenre1',
    async (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(featuredGenre)));
    }
  ),
  rest.get(
    'https://pipes2-server-example.herokuapp.com/collections/genres',
    async (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(genres)));
    }
  ),
  rest.get(
    'https://pipes2-server-example.herokuapp.com/collections/channels',
    async (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(channels)));
    }
  ),
  rest.get(
    'https://pipes2-server-example.herokuapp.com/preset-epg-channel',
    async (_, res, ctx) => {
      return res(ctx.json(replaceRemoteImageSrc(channelsEpg)));
    }
  ),
];

export { feedHandlers };

export const replaceRemoteImageSrc = (json: any) => {
  try {
    return JSON.parse(
      JSON.stringify(json, (key, value) => {
        if (key === 'selected_action_icon' || key === 'favorites_action_icon') {
          return `/Fav-Active.png`;
        }

        if (
          key === 'unselected_action_icon' ||
          key === 'unfavorites_action_icon'
        ) {
          return `/Fav-Default.png`;
        }

        if (value && typeof value === 'string' && value.includes('.jpg')) {
          value = value.replace('.jpg', '.png');
        }
        if (
          typeof value === 'string' &&
          ['.png', '.jpg', 'data:image/png;', 'data:image/jpeg;'].some((e) =>
            value.includes(e)
          )
        )
          return '/16x9.png';

        return value;
      })
    );
  } catch (e) {
    logger.info(`replaceRemoteImageSrc failed: ${e}`);
  }
};
