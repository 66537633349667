const favoritesIds: any = ['show-1', 'show-2', 'show-3', 'show-4', 'show-5'];

const entry = favoritesIds.map((id: string) => ({
  id,
  title: 'Episode Title',
  summary: 'S1:E9 (show-9) Summary',
  type: { value: 'example-future-program' },
  extensions: {
    genre: 'genre-9',
    duration: 3600,
    showId: id.slice(0, id.indexOf('--')),
    channel: 'channel-1',
    seasonNumber: 1,
    episodeNumber: 9,
    relativeBroadcastDate: '2 days ago',
    broadcastDate: 'May 15, 12:00AM',
    broadcastTimeSlot: '00:00-01:00',
    hqme: true,
    hqmeExpirationDate: 1684325074844,
    analyticsCustomProperties: {
      showId: 'show-9',
      genre: 'genre-9',
      channel: 'channel-1',
      seasonNumber: 1,
      episodeNumber: 9,
    },
  },
  media_group: [
    {
      type: 'image',
      media_item: [
        {
          src: 'https://pipes2-server-example.herokuapp.com/images/full-16x9.png?show-9--season-1--episode-9',
          key: 'full-16x9',
        },
        {
          src: 'https://pipes2-server-example.herokuapp.com/images/half-2x3.png?show-9--season-1--episode-9',
          key: 'half-2x3',
        },
        {
          src: 'https://pipes2-server-example.herokuapp.com/images/third-1x1.png?show-9--season-1--episode-9',
          key: 'third-1x1',
        },
        {
          src: 'https://pipes2-server-example.herokuapp.com/images/channel-1.png',
          key: 'v1',
        },
        {
          src: 'https://pipes2-server-example.herokuapp.com/images/channel-1-V2.png',
          key: 'v2',
        },
      ],
    },
  ],
}));

const mockFavorites = {
  feed: {
    id: 'mock-favorites',
    title: 'Favorites',
    type: { value: 'example-epg' },
    entry,
  },
};

export default mockFavorites;
